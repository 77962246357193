<!--
 * @Description: 拆卸,维修,巡检审核弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-22 17:13:23
 * @LastEditTime: 2023-03-07 15:31:06
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <!-- 补录信息 -->
    <el-dialog
      v-dialogDrag
      :title="dialogType == 2 ? '维修' : '补录信息'"
      :visible.sync="auditVisible"
      width="1100px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="add-concat-dialog"
    >
      <div class="audit-content">
        <el-form :inline="true" label-width="100px">
          <el-form-item label="场景名称">
            {{ basicInfo.sceneName }}
          </el-form-item>
          <el-form-item label="场景类型">
            {{ basicInfo.sceneTypeName }}
          </el-form-item>
        </el-form>
        <el-form
          ref="auditForm"
          label-width="100px"
          :model="auditForm"
          :rules="auditFormRules"
          :inline="true"
        >
          <el-form-item label="工程师" prop="engineerrpName">
            <el-input
              v-model.trim="basicInfo.engineerrpName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="作业时间" prop="finishTime">
            <el-date-picker
              v-model="auditForm.finishTime"
              type="datetime"
              placeholder="选择日期"
              value-format="timestamp"
              popper-class="special"
              :disabled="basicInfo.fixDateStr ? true : false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="主体"
            prop="mainBodyId"
            v-if="basicInfo.taskSecondClass == 10"
          >
            <e6-vr-select
              v-model="auditForm.mainBodyId"
              :data="mainBodyList"
              placeholder="主体"
              title="主体"
              :props="{
                id: 'faultClassId',
                label: 'faultName'
              }"
              clearable
              @change="changeMainbody"
              @clear="
                () => {
                  clear('mainBody');
                }
              "
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="故障现象"
            prop="quesReasonId"
            v-if="basicInfo.taskSecondClass == 10"
          >
            <e6-vr-select
              v-model="auditForm.quesReasonId"
              :data="quesReasonList"
              placeholder="故障现象"
              title="故障现象"
              :props="{
                id: 'faultClassId',
                label: 'faultName'
              }"
              clearable
              @change="changeQuesReason"
              @clear="
                () => {
                  clear('quesReason');
                }
              "
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="处理措施"
            prop="solutionId"
            v-if="basicInfo.taskSecondClass == 10"
          >
            <e6-vr-select
              v-model="auditForm.solutionId"
              :data="measureList"
              placeholder="处理措施"
              title="处理措施"
              :props="{
                id: 'faultClassId',
                label: 'faultName'
              }"
              clearable
              @change="changeSolution"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="客责确认"
            prop="engineerResponsibilityId"
            v-if="basicInfo.taskSecondClass == 10"
          >
            <e6-vr-select
              v-model="auditForm.engineerResponsibilityId"
              :data="engineerResponsibilityList"
              placeholder="客责确认"
              title="客责确认"
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
              clearable
              @change="changeEngineerResponsibility"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="故障原因"
            prop="solutionCode"
            v-if="basicInfo.taskSecondClass == 10 && basicInfo.isGrayscaleWork"
          >
            <e6-select
              v-model="auditForm.solutionCode"
              :dataList="solutionList"
              :filterable="true"
              :slotTemplate="'tree'"
              :multiple="false"
              :issingleleaf="true"
              ref="t_select"
              @multipleSelect="multipleSelect"
            ></e6-select>
          </el-form-item>
        </el-form>

        <!-- 设备明细 -->
        <template>
          <div class="detail-title">设备明细</div>
          <el-table :data="equipDetailTableData" max-height="350">
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in columnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
              <template slot-scope="{ row }">
                <span v-if="column.fieldName == 'equipOp'">
                  <e6-vr-select
                    v-model="row.equipOp"
                    :data="equipOpList"
                    placeholder="设备操作"
                    title="设备操作"
                    :disabled="row.isReadOnly == 1"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    @change="handleEquip(row)"
                  ></e6-vr-select>
                </span>
                <span
                  v-else-if="
                    column.fieldName == 'dismantleStatus' && row.equipOp == 2
                  "
                >
                  <e6-vr-select
                    v-model="row.dismantleStatus"
                    :data="dismantleStatusList"
                    placeholder="原设备去向"
                    :disabled="row.isReadOnly == 1"
                    title="原设备去向"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                  ></e6-vr-select>
                  <!--   @change="
                      (val, node) => {
                        handleDismantleStatus(val, node, row);
                      }
                    " -->
                </span>
                <span
                  v-else-if="
                    column.fieldName == 'cardOp' &&
                      row.equType == 1 &&
                      row.cardOp
                  "
                >
                  <e6-vr-select
                    v-model="row.cardOp"
                    :data="row.oldSimNo ? cardOpList1 : cardOpList"
                    placeholder="卡操作"
                    :disabled="row.isReadOnly == 1"
                    title="卡操作"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    @change="handleCard(row)"
                  ></e6-vr-select>
                </span>
                <span
                  v-else-if="column.fieldName == 'cardOp' && row.equType != 1"
                >
                </span>
                <span
                  v-else-if="
                    column.fieldName == 'simNo' &&
                      (row.cardOp == 3 || row.cardOp == 2)
                  "
                >
                  <el-button
                    type="text"
                    :disabled="row.isReadOnly == 1"
                    @click="handleSim(row)"
                    v-if="row.cardOp == 3 || row.cardOp == 2"
                    >{{ row.simNo ? row.simNo : "请选择SIM卡号" }}</el-button
                  >
                </span>
                <span
                  v-else-if="
                    column.fieldName === 'commids' &&
                      row.equType == 1 &&
                      (row.equipOp == 2 || row.equipOp == 3)
                  "
                >
                  <el-input
                    v-model.trim="row.commids"
                    :disabled="
                      row.isReadOnly == 1 ||
                      row.disabledCommids ||
                      row.isAutoObtain ||
                      basicInfo.taskSecondClass == 12
                        ? true
                        : false
                    "
                    maxlength="20"
                  ></el-input>
                  <!--   @input="row.commids = row.commids.replace(/[^0-9-]+/, '')" -->
                </span>
                <span v-else>{{ row[column.fieldName] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <!-- 作业图片 -->
        <template>
          <div class="title">上传凭证</div>
          <div class="img-content">
            <div class="img-item" v-for="(item, index) in imgList" :key="index">
              <uploadOBS
                class="avatar-uploader"
                :fileType="2"
                :limitSize="10"
                :showFileList="false"
                :showBtnType="0"
                :canRemove="false"
                :limitConfig="['jpeg', 'png', 'bmp', 'gif', 'jpg']"
                @success="
                  res => {
                    handleAvatarSuccess(res, item);
                  }
                "
              >
                <div slot="preview">
                  <img
                    v-if="item.fileUrl"
                    :src="item.fileUrl"
                    class="avatar"
                    :preview-src-list="item.fileUrl"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </uploadOBS>
              <div class="img-title">{{ item.photoPositionName }}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
        <el-button
          type="primary"
          @click="sendBack"
          v-if="!basicInfo.fixDate && basicInfo.itemStatus == 7"
          >回到未分配</el-button
        >
      </div>
    </el-dialog>

    <!-- 更换设备弹框 -->
    <change-equip
      :changeEquipVisible="changeEquipVisible"
      @close="close"
      :equipRow="equipRow"
      :basicInfo="basicInfo"
      @updateEquip="updateEquip"
      :secondClassName="equipRow.secondClassName"
      :thirdClassName="equipRow.thirdClassName"
      v-if="!basicInfo.isGrayscaleWork"
    ></change-equip>
    <!-- 更换设备弹框新 -->
    <new-change-equip
      v-else
      :changeEquipVisible="changeEquipVisible"
      @close="close"
      :equipRow="equipRow"
      :basicInfo="basicInfo"
      @updateEquip="updateEquip"
      :secondClassName="equipRow.secondClassName"
      :thirdClassName="equipRow.thirdClassName"
    ></new-change-equip>
    <!-- 添加费用 -->
    <add-equip-fee
      :addFeeVisible="addFeeVisible"
      @closeDialog="closeDialog"
      @updateFee="updateFee"
      :terminalId="equipRow.terminalId"
    ></add-equip-fee>
    <!-- 选择sim卡 -->
    <select-sim
      :engineerrpId="basicInfo.engineerrpId"
      :simVisible="simVisible"
      @closeSim="closeSim"
      @selectSim="selectSim"
      v-if="simVisible"
    ></select-sim>
  </div>
</template>

<script>
import base from "@/mixins/base";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
import {
  findDownList,
  equipUpdateOwnerAndHave,
  otherTaskCollection,
  engineerSimList,
  checkAllEquip,
  queryRepairSolutionList,
  getEquipBindInfo,
  getFaultClassByParent
} from "@/api";
import { printError } from "@/utils/util";
import dialogMixins from "../mixins/index";
import addEquipFee from "../addEquipFee.vue";
import changeEquip from "../changeEquip.vue";
import newChangeEquip from "../newChangeEquip.vue";
import selectSim from "../selectSim.vue";
export default {
  name: "otherDialog",
  components: {
    changeEquip,
    selectSim,
    addEquipFee,
    uploadOBS,
    newChangeEquip
  },
  data() {
    return {
      dialogLoading: false,
      auditForm: {
        quesReason: "", //诊断问题
        quesReasonId: "",
        mainBodyId: "",
        mainBody: "",
        solution: "", //处理方案
        finishTime: "", //作业时间
        solutionCode: "",
        solutionName: "",
        solutionId: "",
        engineerResponsibilityId: "",
        engineerResponsibility: ""
      },
      engineerResponsibilityList: [], //客责
      mainBodyList: [], //主体
      solutionList: [], //故障原因
      quesReasonList: [], //故障现象
      measureList: [], //处理措施
      equipRow: {},
      simVisible: false,
      auditFormRules: {
        finishTime: [
          {
            required: true,
            message: "请选择作业时间",
            trigger: ["blur", "change"]
          }
        ],
        mainBodyId: [
          {
            required: true,
            message: "请选择主体",
            trigger: ["blur", "change"]
          }
        ],
        quesReasonId: [
          {
            required: true,
            message: "请选择故障现象",
            trigger: ["blur", "change"]
          }
        ],
        solutionId: [
          {
            required: true,
            message: "请选择处理措施",
            trigger: ["blur", "change"]
          }
        ],
        engineerResponsibilityId: [
          {
            required: true,
            message: "请选择客责确认",
            trigger: ["blur", "change"]
          }
        ]
      },
      equipOpList: [], //设备操作类型
      equipOpList1: [], //维修/巡检单的设备操作类型
      equipOpList2: [], //拆卸单的设备操作类型
      cardOpList: [], //卡操作类型
      cardOpList1: [],
      dismantleStatusList: [], //原设备去向
      simList: [], //工程师名下sim卡
      /*******设备明细********/
      /******* 换卡*******/
      changeCardVisible: false,
      /******换机*******/
      handleType: "", //设备操作类型
      changeEquipVisible: false,
      /*******添加费用********/
      addFeeVisible: false
      // 更换设备
    };
  },
  props: ["basicInfo", "auditVisible", "dialogType"],
  mixins: [base, dialogMixins],
  computed: {
    columnData() {
      let columnData = [
        {
          fieldName: "equipOp",
          fieldLabel: "设备操作",
          width: 160,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "cardOp",
          fieldLabel: "卡操作",
          width: 160,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "commids",
          fieldLabel: "中心识别码",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simNo",
          fieldLabel: "新SIM卡",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "oldSimNo",
          fieldLabel: "旧SIM卡",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          fieldLabel: "商品名称",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          fieldLabel: "商品分类",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          fieldLabel: "商品开票名称",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equCombination",
          fieldLabel: "安装组合",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipCode",
          fieldLabel: "新设备编号",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "oldEquipCode",
          fieldLabel: "旧设备编号",
          width: 140,
          fixed: false,
          align: "center"
        }
      ];
      if (this.basicInfo.isGrayscaleWork) {
        columnData = [
          {
            fieldName: "equipOp",
            fieldLabel: "设备操作",
            width: 160,
            fixed: true,
            align: "center"
          },
          {
            fieldName: "dismantleStatus",
            display: false,
            fieldLabel: "原设备去向",
            width: 160,
            fixed: true,
            align: "center"
          },
          {
            fieldName: "cardOp",
            fieldLabel: "卡操作",
            width: 160,
            fixed: true,
            align: "center"
          },
          {
            fieldName: "commids",
            fieldLabel: "物料名称",
            width: 140,
            fixed: false,
            align: "center"
          },
          {
            fieldName: "commids",
            fieldLabel: "中心识别码",
            width: 140,
            fixed: false,
            align: "center"
          },
          {
            fieldName: "simNo",
            fieldLabel: "新SIM卡",
            width: 160,
            fixed: false,
            align: "center"
          },
          {
            fieldName: "oldSimNo",
            fieldLabel: "旧SIM卡",
            width: 160,
            fixed: false,
            align: "center"
          },
          {
            fieldName: "thirdClassName",
            fieldLabel: "商品名称",
            width: 140,
            fixed: false,
            align: "center"
          },
          {
            fieldName: "firstClassName",
            fieldLabel: "商品分类",
            width: 140,
            fixed: false,
            align: "center"
          },
          {
            fieldName: "secondClassName",
            fieldLabel: "商品开票名称",
            width: 140,
            fixed: false,
            align: "center"
          },
          {
            fieldName: "equCombination",
            fieldLabel: "安装组合",
            width: 140,
            fixed: false,
            align: "center"
          },
          {
            fieldName: "equipCode",
            fieldLabel: "新设备编号",
            width: 140,
            fixed: false,
            align: "center"
          },
          {
            fieldName: "oldEquipCode",
            fieldLabel: "旧设备编号",
            width: 140,
            fixed: false,
            align: "center"
          }
        ];
      } else {
        if (this.basicInfo.taskSecondClass == 12) {
          columnData = [
            {
              fieldName: "equipOp",
              fieldLabel: "设备操作",
              width: 160,
              fixed: true,
              align: "center"
            },
            {
              fieldName: "thirdClassName",
              fieldLabel: "商品名称",
              width: 160,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "firstClassName",
              fieldLabel: "商品分类",
              width: 160,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "secondClassName",
              fieldLabel: "商品开票名称",
              width: 160,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "equCombination",
              fieldLabel: "安装组合",
              width: 160,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "equipCode",
              fieldLabel: "设备编号",
              width: 160,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "oldSimNo",
              fieldLabel: "旧SIM卡号",
              width: 160,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "commids",
              fieldLabel: "中心识别码",
              width: 160,
              fixed: false,
              align: "center"
            }
          ];
        }
      }
      return columnData;
    }
  },
  watch: {
    auditVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initData();
          this.getInstallDetail();
          this.getCredentialReq();
          this.auditForm.finishTime = this.basicInfo.fixDateStr
            ? this.basicInfo.fixDateStr
            : "";
          if (this.basicInfo.taskSecondClass == 10) {
            this.auditForm.quesReason = this.basicInfo.quesReason
              ? this.basicInfo.quesReason
              : "";
            this.auditForm.solution = this.basicInfo.solution
              ? this.basicInfo.solution
              : "";
            this.auditForm.engineerResponsibilityId = this.basicInfo.engineerResponsibilityId.toString();
            //客责
            this.auditForm.mainBodyId = this.basicInfo.mainBodyId
              ? this.basicInfo.mainBodyId
              : ""; //主体
            this.auditForm.quesReasonId = this.basicInfo.quesReasonId
              ? this.basicInfo.quesReasonId
              : ""; //故障现象
            this.auditForm.solutionId = this.basicInfo.solutionId
              ? this.basicInfo.solutionId
              : ""; //处理措施
          }
        }
      }
    }
  },
  created() {},
  methods: {
    //获取工程师所有信息
    async initData() {
      try {
        this.dialogLoading = true;
        let promiseList = [
          findDownList([
            "equipOp",
            "cardOp",
            "equipOpNew",
            "disassembleEquipOp",
            "dismantleStatus",
            "engineerResponsibility"
          ]), //获取工程师名下sim卡
          engineerSimList({
            id: this.basicInfo.engineerrpId
          }),
          queryRepairSolutionList(),
          getFaultClassByParent({
            parentId: 0,
            parentFaultType: 0,
            faultType: 1
          })
        ];
        let [res, simRes, solutionRes, mainBodyRes] = await Promise.all(
          promiseList
        );
        //客责
        this.engineerResponsibilityList = this.getFreezeResponse(res, {
          path: "data.engineerResponsibility"
        });
        //主体
        this.mainBodyList = this.getFreezeResponse(mainBodyRes, {
          path: "data"
        });
        //设备操作类型
        this.equipOpList1 = this.getFreezeResponse(res, {
          path: "data.equipOp"
        });
        //灰度客户设备操作类型
        this.equipOpList3 = this.getFreezeResponse(res, {
          path: "data.equipOpNew"
        });
        //设备操作类型
        this.equipOpList2 = this.getFreezeResponse(res, {
          path: "data.disassembleEquipOp"
        });
        if (this.basicInfo.taskSecondClass == 12) {
          this.equipOpList = this.equipOpList2;
        } else {
          this.equipOpList = this.basicInfo.isGrayscaleWork
            ? this.equipOpList3
            : this.equipOpList1;
        }
        //卡操作类型
        this.cardOpList = this.getFreezeResponse(res, {
          path: "data.cardOp"
        });
        this.cardOpList.map(item => {
          if (item.codeName != "装卡") {
            this.cardOpList1.push(item);
          }
        });
        this.auditForm.solutionCode = this.basicInfo.solutionCode
          ? this.basicInfo.solutionCode.toString()
          : "";
        //设备去向
        this.dismantleStatusList = this.getFreezeResponse(res, {
          path: "data.dismantleStatus"
        });
        //获取工程师名下sim卡
        this.simList = this.getFreezeResponse(simRes, {
          path: "data"
        });
        //故障原因
        let solutionList = solutionRes.data;
        solutionList.map(item => {
          item.id = item.parentCode;
          item.pid = 0;
          item.disabled = false;
          item.label = item.parentName;
          item.children = item.solutionDetailDTOS;
          item.children.map(each => {
            each.pid = item.id;
            each.id = each.solutionCode;
            each.label = each.solutionName;
            each.disabled = false;
          });
        });
        console.log(solutionList);
        this.solutionList = solutionList;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //清空主体、故障
    clear(type) {
      this.measureList = [];
      this.auditForm.solution = "";
      this.auditForm.solutionId = "";
      if (type == "mainBody") {
        this.quesReasonList = [];
        this.auditForm.quesReasonId = "";
        this.auditForm.quesReason = "";
      }
    },
    //选择客责
    changeEngineerResponsibility(val, node) {
      this.auditForm.engineerResponsibility = node.codeName;
    },
    //选中主体
    changeMainbody(val, node) {
      this.auditForm.mainBody = node.faultName;
      this.getFaultClassByParentReq(node.faultClassId, 1, 2);
    },
    //选中故障现象
    changeQuesReason(val, node) {
      this.auditForm.quesReason = node.faultName;
      this.getFaultClassByParentReq(node.faultClassId, 2, 4);
    },
    //选中处理措施
    changeSolution(val, node) {
      this.auditForm.solution = node.faultName;
    },
    async getFaultClassByParentReq(parentId, parentFaultType, faultType) {
      try {
        let res = await getFaultClassByParent({
          parentId,
          parentFaultType,
          faultType
        });
        if (res.code == "OK") {
          if (faultType == 2) {
            //故障现象
            this.quesReasonList = this.getFreezeResponse(res, {
              path: "data"
            });
          }
          if (faultType == 4) {
            //处理措施
            this.measureList = this.getFreezeResponse(res, {
              path: "data"
            });
          }
        }
      } catch (error) {
        this.dialogLoading = false;
        printError(error);
      }
    },
    //选择故障
    multipleSelect(node) {
      if (node.length) {
        this.auditForm.solutionName = node[0].label;
      }
    },
    //重置form
    resetForm() {
      this.auditForm = {
        finishTime: "", //作业时间
        sceneName: "", //	场景名称
        sceneType: "", //场景类型1车，2门店，3仓库
        vin: "", //车架号
        vehicleBrand: "", //车辆品牌
        vehicleBrandName: "",
        insideLength: "", //	车厢长度（cm）
        overallLength: "", //	长
        overallWidth: "", //	宽
        overallHeight: "", //	高
        vehicleMarker: "" //	车型分类(1普货厢车 2冷藏厢车 3半挂牵引车（普货） 4半挂牵引车（冷藏） 5危险品运输 其它)
      };
      this.equipDetailTableData = [];
      this.$refs.auditForm.resetFields();
      this.imgList = [];
    },
    //点击确定
    handleConfirm() {
      this.$refs.auditForm.validate(valid => {
        if (valid) {
          let msg = this.dialogType == 2 ? "维修信息" : "补录信息";
          this.$confirm(
            `是否确认提交${msg}，提交后将不能再更改！`,
            `${msg}确认`,
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }
          )
            .then(() => {
              this.checkAllEquipReq();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消"
              });
            });
        }
      });
    },
    //如果查询的是所有设备，需校验是否可用
    async checkAllEquipReq() {
      try {
        this.dialogLoading = true;
        let equipCodeList = this.equipDetailTableData.map(
          item => item.equipCode
        );
        let res = await checkAllEquip({
          equipCodeList,
          engineerrpId: this.basicInfo.engineerrpId,
          itemId: this.basicInfo.itemId
        });
        if (res.code == "OK") {
          if (res.data.isTips) {
            //需要提示的调用流转接口
            this.$confirm(res.data.tips, "", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                this.noEngineerEquipCodeList = res.data.noEngineerEquipCodeList;
                this.wareHouseEquipCodeList = res.data.wareHouseEquipCodeList;
                this.noProjectEquipCodeList = res.data.noProjectEquipCodeList;
                this.otherTaskCollection();
                // this.equipUpdateOwnerAndHaveReq();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消"
                });
              });
          } else {
            //不需要提示的直接提交
            this.otherTaskCollection();
          }
        }
      } catch (error) {
        this.dialogLoading = false;
        printError(error);
      }
    },
    //设备流转
    async equipUpdateOwnerAndHaveReq() {
      try {
        this.dialogLoading = true;
        let res = await equipUpdateOwnerAndHave({
          noEngineerEquipCodeList: this.noEngineerEquipCodeList,
          wareHouseEquipCodeList: this.wareHouseEquipCodeList,
          noProjectEquipCodeList: this.noProjectEquipCodeList,
          engineerrpId: this.basicInfo.engineerrpId
        });
        if (res.code == "OK") {
          this.otherTaskCollection();
        }
      } catch (error) {
        this.dialogLoading = false;
        printError(error);
      }
    },
    //拆卸、维修、巡检补录信息
    async otherTaskCollection() {
      try {
        this.dialogLoading = true;
        let equipDetailTableData = _.cloneDeep(this.equipDetailTableData);
        let dismantleStatusList = this.dismantleStatusList;
        equipDetailTableData.map(item => {
          item.dismantleStatusName = item.dismantleStatus
            ? dismantleStatusList.find(
                ele => ele.codeValue == item.dismantleStatus
              ).codeName
            : "";
        });
        let res = await otherTaskCollection({
          ...this.auditForm,
          itemId: this.basicInfo.itemId,
          taskSecondClass: this.basicInfo.taskSecondClass,
          supplementEuqReqVOList: equipDetailTableData,
          photoLocationReqVOList: this.imgList,
          versionNumber: this.basicInfo.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.$emit("refresh");
          this.resetForm();
          this.handleClose();
          // eventBus.$emit("SHOW-DIALOG");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.$emit("handleClose", "auditVisible");
    },
    /*****换卡操作*********/
    closeDialog(name) {
      this[name] = false;
    },
    //选中换卡
    handleCard(row) {
      if (row.cardOp == 3 || row.cardOp == 2) {
        this.equipRow = row;
        this.simVisible = true;
      }
    },
    //点击选择sim卡
    handleSim(row) {
      if (this.basicInfo.isGrayscaleWork && row.disabledSim) return;
      this.equipRow = row;
      this.simVisible = true;
    },
    //选中sim卡
    selectSim(simData) {
      this.equipRow.simNo = simData.simNo;
      this.equipRow.simId = simData.simId;
      this.equipRow.isSelfOwn = simData.isSelfOwn;
      this.closeSim();
    },
    closeSim() {
      this.simVisible = false;
    },

    /******换机/换新*******/
    //选中换机、换新
    handleEquip(row) {
      if (row.equipOp == 2 || row.equipOp == 3) {
        if (this.basicInfo.isGrayscaleWork && !row.isBatchManage) return;
        this.handleType = row.equipOp;
        this.changeEquipVisible = true;
        this.equipRow = row;
      } else {
        if (this.basicInfo.taskSecondClass == 10) {
          row.commids = "";
          this.equipRow = row;
        }
      }
    },
    //原设备去向
    handleDismantleStatus(val, node, row) {
      row.dismantleStatusName = node.codeName;
    },
    //更新设备编号
    updateEquip(newEquip) {
      if (this.basicInfo.isGrayscaleWork) {
        this.equipRow.equipCode = newEquip.equipNo || newEquip.equipCode;
        this.equipRow.newEquipCode = newEquip.equipNo || newEquip.equipCode;
        this.equipRow.equSource = newEquip.equSource;
        if (this.equipRow.equType == 1) {
          this.getEquipBindInfoReq(newEquip);
        } else {
          this.changeEquipVisible = false;
        }
      } else {
        this.equipRow.equipCode = newEquip.equipNo || newEquip.equipCode;
        this.equipRow.newEquipCode = newEquip.equipNo || newEquip.equipCode;
        this.equipRow.equSource = newEquip.equSource;
        this.equipRow.qualityMonth = newEquip.qualityMonth; //设备保修期 单位：月
        this.equipRow.firstClassId = newEquip.firstClassId;
        this.equipRow.firstClassName = newEquip.firstClassName;
        this.equipRow.secondClassId = newEquip.secondClassId;
        this.equipRow.secondClassName = newEquip.secondClassName;
        this.equipRow.thirdClassId = newEquip.thirdClassId;
        this.equipRow.thirdClassName = newEquip.thirdClassName;
        if (this.equipRow.equType == 1) {
          this.getEquipBindInfoReq(newEquip);
        }
        this.changeEquipVisible = false;
      }

      if (this.handleType == 3 && !this.basicInfo.isGrayscaleWork) {
        this.addFeeVisible = true;
      }
    },
    //根据设备编号查询sim
    async getEquipBindInfoReq(newEquip) {
      try {
        let res = await getEquipBindInfo({
          equipCode: newEquip.equipNo || newEquip.equipCode
        });
        if (res.code == "OK") {
          let data = res.data;
          if (data.simNo) {
            if (this.basicInfo.sourceOrganization == 1 && !data.simId) {
              this.equipRow.newEquipCode = "";
              this.equipRow.equipCode = "";
              this.equipRow.simNo = "";
              this.equipRow.simId = "";
              this.equipRow.isSelfOwn = "";
              this.$message.warning("请联系供应链赵小兵在E3 2.0采购入库SIM");
              return;
            } else {
              this.equipRow.simNo = data.simNo;
              this.equipRow.simId = data.simId;
              this.equipRow.isSelfOwn = data.isSelfOwn;
              this.equipRow.disabledSim = true;
            }
          }
          //设备上线易流云， 没有IMEI， 没有中心识别码： 需要录入中心识别码
          // 设备上线易流云， 有IMEI， 没有中心识别码：需要录入中心识别码
          // 设备上线易流云， 有IMEI， 有中心识别码： 不需要录入中心识别码
          // 设备上线G7S， 没有IMEI， 没有中心识别码：需要录入中心识别码
          // 设备上线G7S， 有IMEI， 没有中心识别码： 不需要录入中心识别码
          // 设备上线G7S， 有IMEI， 有中心识别码： 不需要录入中心识别码
          // sourceOrganization上线平台（1E6yun，2G7s）
          //灰度客户
          if (this.basicInfo.isGrayscaleWork) {
            let sourceOrganization = this.basicInfo.sourceOrganization;
            this.equipRow.commids = data.commIds;
            this.equipRow.centerId = data.commIds;
            this.equipRow.isAutoObtain = data.commIds ? 1 : 0;
            if (sourceOrganization == 1 && !data.commIds) {
              this.equipRow.disabledCommids = false;
            }
            if (sourceOrganization == 1 && data.imei && data.commIds) {
              this.equipRow.disabledCommids = true;
            }
            if (sourceOrganization == 2 && !data.imei && !data.commIds) {
              this.equipRow.disabledCommids = false;
            }
            if (sourceOrganization == 2 && data.imei) {
              this.equipRow.disabledCommids = true;
            }
          } else {
            // 设备上线易流云， 没有IMEI， 没有中心识别码： 需要录入中心识别码
            // 设备上线易流云， 有IMEI， 没有中心识别码：需要录入中心识别码
            // 设备上线易流云， 有IMEI， 有中心识别码： 不需要录入中心识别码
            this.equipRow.commids = data.commIds;
            this.equipRow.centerId = data.commIds;
            this.equipRow.isAutoObtain = data.commIds ? 1 : 0;
            if (!data.commIds) {
              this.equipRow.disabledCommids = false;
            } else {
              this.equipRow.disabledCommids = true;
            }
          }
          this.changeEquipVisible = false;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //更新费用
    updateFee(feeInfo) {
      this.equipRow.fixMoney = feeInfo.fixMoney; //金额
      this.equipRow.fixCatId = feeInfo.fixCatId; //条目ID
      this.equipRow.priceFrom = feeInfo.priceFrom;
      this.addFeeVisible = false;
    },
    //关闭弹框
    close() {
      this.changeEquipVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.audit-content {
  // margin-top: 20px;
  .title {
    padding-left: 20px;
    box-sizing: border-box;
    // margin-bottom: 15px;
    margin-top: 15px;
  }
  .detail-title {
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 15px;
    &::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
  .img-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    /deep/ .img-item {
      margin-top: 20px;
      margin-right: 15px;
      .avatar-uploader {
        .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          &:hover {
            border-color: #409eff;
          }
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 110px;
          height: 90px;
          line-height: 90px;
          text-align: center;
        }
        .avatar {
          width: 110px;
          height: 90px;
          display: block;
        }
      }
      .img-title {
        width: 100%;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
}
</style>
